import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { rem } from "../styles/utils";

const circular = css`
  border-radius: 100%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
`;

export const VideoWrap = styled.div`
  position: relative;
  overflow: hidden;

  ${({ circle }) => circle && circular};

  video {
    min-width: 100%;
    min-height: 100%;

    width: auto;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;
