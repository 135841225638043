import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Btn, { btnField } from "app/components/btn";
import Image from "app/components/image";
import MarkdownBlock from "app/components/markdown-block";
import PlayIcon from "app/components/icons/play-icon";

import useVideoModal, { isVideoUrl } from "../hooks/use-video-modal";

import * as S from "app/components/hero.styles";

const Hero = ({
  btn,
  className,
  image,
  hasMarkdown,
  fade,
  isDark,
  isTall,
  text,
  title,
  videoBg,
}) => {
  const { openModal } = useVideoModal();
  const isVideo = isVideoUrl(_get(btn, "url"));
  const TitleStyle = isVideo ? S.HeroTitle : S.HeroHeadline;

  return (
    <section
      className={className}
      css={[
        S.heroBox,
        (image || videoBg) && S.bgImage,
        (isTall || image) && S.tall,
        isDark && S.dark,
      ]}
    >
      {fade && <S.HeroBGFade />}
      {image && <Image css={S.cover} image={image} />}
      {videoBg && (
        <video css={S.cover} autoPlay loop muted playsInline>
          <source src={videoBg} type={"video/mp4"} />
          Your browser does not support the video tag.
        </video>
      )}
      <S.HeroContent>
        {isVideo && (
          <PlayIcon
            css={S.playBtn}
            onClick={() => openModal(_get(btn, "url"))}
          />
        )}
        {title && <TitleStyle>{title}</TitleStyle>}
        {text && (
          <S.HeroBody>
            {!hasMarkdown && text}
            {hasMarkdown && (
              <MarkdownBlock
                content={text}
                components={{ p: React.Fragment }}
              />
            )}
          </S.HeroBody>
        )}
        {btn && <Btn {...btn} />}
      </S.HeroContent>
    </section>
  );
};

export const heroFragment = graphql`
  fragment HeroFields on ContentJsonHero {
    title
    text
    image {
      childImageSharp {
        gatsbyImageData(width: 1400)
      }
    }
    btn {
      ...ButtonFields
    }
  }

  fragment Hero on ContentJson {
    hero {
      ...HeroFields
    }
  }
`;

export const heroField = {
  label: "Hero",
  name: "hero",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Text",
      name: "text",
      widget: "markdown",
      required: false,
    },
    {
      label: "Image",
      name: "image",
      widget: "image",
      required: false,
    },
    {
      label: "Use Dark Text",
      name: "isDark",
      widget: "boolean",
      required: false,
    },
    btnField,
  ],
};

export default Hero;
