import React, { memo } from "react";

import * as S from "./video-background.styles";

const VideoBG = ({ src, mediaType, children, ...props }) => (
  <S.VideoWrap {...props}>
    <video autoPlay loop muted playsInline>
      <source src={src} type={mediaType} />
      Your browser does not support the video tag.
    </video>
    {children}
  </S.VideoWrap>
);

VideoBG.defaultProps = {
  mediaType: "video/mp4"
};

export default memo(VideoBG);
