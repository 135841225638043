import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";
import { rem, colors, fontSize, fontWeights } from "../styles/utils";
import { ABody } from "./article.styles";
import VideoBG from "./video-background";

import {
  H1,
  H2,
  Container,
  centerTxt,
  bannerTxt,
  bodyTxt,
  innerContainer,
} from "../styles/shared";
import BackgroundImage from "gatsby-background-image";

export const HeroBox = styled.section`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(60)};
`;

export const heroBox = css`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(60)};
`;

export const bgImage = css`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(70)} 0;
  min-height: ${rem(360)};
  color: ${colors.white};
`;

export const dark = css`
  color: black;
`;

export const tall = css`
  padding-top: ${rem(120)};
  min-height: ${rem(360)};
`;

export const gradient = css`
  background: linear-gradient(286.77deg, #b642a0 0%, #2f23ad 100%);
`;

export const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
`;

export const HeroBGFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: 2;
`;

const heroBg = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(70)} 0;
  min-height: ${rem(360)};
  color: ${colors.white};
`;

export const HeroBG = styled(BackgroundImage)`
  ${heroBg}
  ${({ dark }) => dark && `color: black;`}
  ${({ tall }) => tall === "true" && tall}
`;

export const HeroVidBG = styled(VideoBG)`
  ${heroBg}
  ${({ dark }) => dark && `color: black;`}
`;

export const HeroGradient = styled.div`
  ${heroBg}
  background: linear-gradient(286.77deg, #b642a0 0%, #2f23ad 100%);
`;

export const HeroContent = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
`;

const titleOnly = css`
  min-height: ${rem(120)};
  justify-content: flex-end;
  margin-bottom: ${rem(40)};
`;

const titleWText = css`
  ${fontSize(18, 24)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
  margin-bottom: ${rem(20)};

  @media (${desktop}) {
    ${fontSize(18, 24)};
  }
`;

const titleStyle = ({ text }) => css`
  ${text !== true && titleOnly};
  ${text === true && titleWText};
`;

export const HeroTitle = styled(H1)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${innerContainer};
  ${titleStyle};
`;

export const HeroSubhead = styled(H2)`
  ${centerTxt};
  margin: 0 auto;
`;

export const HeroHeadline = styled.h2`
  ${centerTxt};
  ${bannerTxt};
  margin: 0 auto ${rem(30)};
`;

export const HeroBody = styled.p`
  ${centerTxt};
  ${bodyTxt};
  margin: 0 auto ${rem(30)};
`;

export const HeroBodyWrap = styled(ABody)`
  ${centerTxt};
  margin: 0 auto ${rem(30)};

  *:last-child {
    margin-bottom: 0;
  }
`;

export const playBtn = css`
  margin: 0 auto ${rem(30)};
  cursor: pointer;
`;
